import React from 'react';
import {BrowserRouter, Route, Switch,} from "react-router-dom";
import SignIn from "./routes/SignIn";
import Session from "./routes/Session";
import './App.css';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={SignIn}/>
                    <Route path="/session/:id" exact component={Session}/>
                    <Route path="*" component={SignIn}/>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;