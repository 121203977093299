import React, {useRef, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import QRCode from "react-qr-code";
import {socket} from '../socket';

const Types = {
    Offer: "Offer",
    Joined: "Joined",
    SignIn: "SignIn",
    Answer: "Answer",
    EndCall: "EndCall",
    TerminateAll: "TerminateAll",
    IceCandidates: "IceCandidates",
    StartStreaming: "StartStreaming",
    RedirectToHome: "RedirectToHome",
    DisconnectRequest: "DisconnectRequest",
}

const Session = (props) => {
    const code = useRef(props.match.params.id);
    const streamer = !!props.location.create;
    const stream = useRef();
    const peerRef = useRef();
    const mainStream = useRef();
    const socketRef = useRef(socket);
    const otherUser = useRef(null);
    const currentUser = useRef();
    const userVideo = useRef();
    const screenTrack = useRef();
    const [copied, setCopied] = useState(false);
    const [showPlayer, setShowPlayer] = useState(false);
    const config = getRTCConfig();

    useEffect(() => {
        peerRef.current = createPeer(config);
        code.current = props.match.params.id;

        currentUser.current = socketRef.current.id
        if (currentUser.current) {
            socketRef.current.emit("message",
                JSON.stringify({
                    type: Types.SignIn,
                    create: streamer,
                    sessionId: props.match.params.id,
                    username: currentUser.current
                })
            );
        } else {
            socket.on('connect', () => {
                console.log("current user false... onconnect func... TRY TO JOIN TO THE ROOM");

                currentUser.current = socketRef.current.id;
                socketRef.current.emit("message", JSON.stringify({
                    type: Types.SignIn,
                    create: streamer,
                    sessionId: props.match.params.id,
                    username: props.location.name ?? socketRef.current.id
                }));
            });
        }

        socketRef.current.on(Types.Joined, handleJoined);
        socketRef.current.on(Types.StartStreaming, handleStartStreaming);
        socketRef.current.on(Types.Offer, handleReceiveCall);
        socketRef.current.on(Types.Answer, handleAnswer);
        socketRef.current.on(Types.IceCandidates, handleNewICECandidateMsg);
        socketRef.current.on(Types.EndCall, handleEndCall);
        socketRef.current.on(Types.RedirectToHome, (msg) => redirectToHome(msg));

        window.addEventListener("beforeunload", refreshAlert);
        return () => {
            window.removeEventListener('beforeunload', refreshAlert);
        };
    }, []);

    function refreshAlert(e) {
        e.preventDefault();
        e.returnValue = "";
    }

    function getRTCConfig() {
        let index = 0;
        let config = {iceServers: []};
        while (process.env[`REACT_APP_TURN_SERVER_${index}_URL`]) {
            config.iceServers.push({
                urls: process.env[`REACT_APP_TURN_SERVER_${index}_URL`],
                ...(process.env[`REACT_APP_TURN_SERVER_${index}_CREDENTIAL`] && {credential: process.env[`REACT_APP_TURN_SERVER_${index}_CREDENTIAL`]}),
                ...(process.env[`REACT_APP_TURN_SERVER_${index}_USERNAME`] && {username: process.env[`REACT_APP_TURN_SERVER_${index}_USERNAME`]}),
            });
            index++;
        }

        return config;
    }

    async function handleJoined(otherId) {
        console.log(`Someone joined (${otherId}). Request to start streaming...`);
        otherUser.current = otherId;

        shareScreen().then((success) => {
            if (success) {
                const payload = {
                    type: Types.StartStreaming,
                    username: currentUser.current,
                    target: otherUser.current
                };
                socketRef.current.emit("message", JSON.stringify(payload))
            }
        });
    }

    function handleStartStreaming(data) {
        data = JSON.parse(data);
        otherUser.current = data.username;

        handleNegotiationNeededEvent();
    }

    function createPeer(configRTC) {
        const peer = new RTCPeerConnection(configRTC);

        peer.onicecandidate = handleICECandidateEvent;
        peer.ontrack = handleTrackEvent;
        peer.onnegotiationneeded = (event) => handleNegotiationNeededEvent(event);

        peer.onsignalingstatechange = ((event) => {
            console.log("SIGNALING state: " + event.currentTarget.signalingState)
        });

        peer.onconnectionstatechange = ((event) => {
            console.log("CONNECTION state: " + event.currentTarget.connectionState);
            if (event.currentTarget.connectionState === "disconnected") {

                const mainStreamTracks = mainStream.current?.getTracks();
                for (let i = 0; i < mainStreamTracks?.length; i++) {
                    mainStreamTracks[i].stop();
                }

                handleEndCall();
            }
        });

        return peer;
    }

    function handleNegotiationNeededEvent() {
        if (peerRef.current.connectionState !== "closed") {
            peerRef.current.createOffer({offerToReceiveVideo: true, offerToReceiveAudio: true})
                .then(offer => {
                    return peerRef.current.setLocalDescription(offer)
                })
                .then(() => {
                    const payload = {
                        type: Types.Offer,
                        target: otherUser.current,
                        username: currentUser.current,
                        data: peerRef.current.localDescription.sdp
                    };
                    socketRef.current.emit("message", JSON.stringify(payload));
                }).catch((e) => {
                console.log(e.message);
            });
        }
    }

    function handleReceiveCall(incoming) {
        incoming = JSON.parse(incoming);

        if (peerRef.current.connectionState !== "closed") {
            const desc = new RTCSessionDescription({type: "offer", sdp: incoming.data});

            peerRef.current.setRemoteDescription(desc).then(() => {
                return peerRef.current.createAnswer({offerToReceiveVideo: true, offerToReceiveAudio: true});
            }).then(answer => {
                return peerRef.current.setLocalDescription(answer);
            }).then(() => {
                const payload = {
                    type: Types.Answer,
                    target: incoming.username,
                    username: currentUser.current,
                    data: peerRef.current.localDescription.sdp
                }

                socketRef.current.emit("message", JSON.stringify(payload));
            }).then(() => {
                if (mainStream.current) {
                    peerRef.current.addStream(mainStream.current);
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }

    function handleAnswer(message) {
        message = JSON.parse(message);
        if (peerRef.current.connectionState !== "closed") {
            const desc = new RTCSessionDescription({type: "answer", sdp: message.data});
            peerRef.current.setRemoteDescription(desc)
        }
    }

    function handleICECandidateEvent(event) {
        console.log("Send ICE");
        if (event.candidate) {
            const payload = {
                type: Types.IceCandidates,
                username: currentUser.current,
                target: otherUser.current,
                data: JSON.stringify(event.candidate),
            }

            socketRef.current.emit("message", JSON.stringify(payload));
        }
    }

    function handleNewICECandidateMsg(incoming) {
        console.log("Received ICE");
        incoming = JSON.parse(incoming);
        incoming.data = JSON.parse(incoming.data);

        if (peerRef.current.connectionState !== "closed") {
            const candidate = new RTCIceCandidate(incoming.data);
            peerRef.current.addIceCandidate(candidate);
        }
    }

    function handleTrackEvent(e) {
        if (!stream.current) {
            stream.current = new MediaStream();
        }
        setShowPlayer(true);
        stream.current.addTrack(e.transceiver.receiver.track)

        setTimeout(() => {
            if (userVideo.current) {
                userVideo.current.srcObject = stream.current;
            }
        });

        if (e.transceiver.receiver.track.kind === "video") {
            screenTrack.current = e.transceiver.receiver.track;

            screenTrack.current.onended = function () {
                peerRef.current.close();
            }
        }
    }

    async function shareScreen() {
        setShowPlayer(true);

        const mediaOption = {
            video: true,
            audio: true,
            cursor: true,
        }

        return await navigator.mediaDevices.getDisplayMedia(mediaOption)
            .then(stream => {
                mainStream.current = stream;
                screenTrack.current = stream.getTracks()[0];
                userVideo.current.srcObject = stream;

                screenTrack.current.onended = function () {
                    const payload = {
                        type: Types.EndCall,
                        username: currentUser.current,
                        target: otherUser.current
                    }

                    socketRef.current.emit("message", JSON.stringify(payload));

                    redirectToHome();
                }
                return true;
            }).catch(e => {
                setShowPlayer(false);
                alert(e)
                redirectToHome();
                return false;
            });
    }

    function handleEndCall() {
        const payload = {type: Types.DisconnectRequest, username: currentUser.current};
        socketRef.current.emit("message", JSON.stringify(payload));
        redirectToHome();
    }

    function redirectToHome(msg = '') {
        if (msg) {
            alert(msg);
        }

        if (peerRef.current) {
            peerRef.current.close();
        }

        props.history.push(`/`);
    }

    function copyCode() {
        navigator.clipboard.writeText(code.current).then(() => setCopied(true));
        setTimeout(() => {
            setCopied(false)
        }, 1000)
    }

    return (
        <div className="full-height">
            {showPlayer ?
                <div className="content center">
                    <video controls id='video' className="video-styles" autoPlay muted={true} ref={userVideo}/>
                </div>
                :
                <div className="div">
                    <Link className="div-2" to="/">
                        <img height="32" src="/logo_vce_glasses.svg" alt="logo"/>
                    </Link>
                    <div className="div-no-toast"></div>
                    <div className={`container-x  ${copied ? "vis-container-x" : "hid-container-x"}`}></div>
                    <div className={`div-3 ${copied ? "vis" : "hid"}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C13.4121 3 14.7482 3.32519 15.9375 3.90476M19.3125 6.375L11.4375 14.25L9.1875 12"
                                stroke="#0F766E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <div className="div-4">The ID has copied</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             onClick={() => setCopied(false)}>
                            <path d="M16 8L8 16M16 16L8 8" stroke="#0B252F" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>

                    <div className="div-5">
                        <div className="div-6">
                            <div className="column">
                                <div className="div-7">
                                    <svg className="icon-big img-4" xmlns="http://www.w3.org/2000/svg" width="25"
                                         height="25" viewBox="0 0 38 38" fill="none">
                                        <path
                                            d="M4.75 30.0834H4.769M11.0833 30.0834C11.0833 28.4037 10.4161 26.7928 9.22834 25.6051C8.04061 24.4173 6.4297 23.7501 4.75 23.7501M17.4167 30.0834C17.4167 26.724 16.0821 23.5022 13.7067 21.1267C11.3312 18.7513 8.10941 17.4168 4.75 17.4168M23.75 30.0834H28.5C29.7598 30.0834 30.968 29.583 31.8588 28.6922C32.7496 27.8014 33.25 26.5932 33.25 25.3334V12.6667C33.25 11.407 32.7496 10.1988 31.8588 9.30799C30.968 8.41719 29.7598 7.91675 28.5 7.91675H9.5C8.52542 7.926 7.57728 8.23481 6.7842 8.8013C5.99112 9.36779 5.3915 10.1645 5.06667 11.0834"
                                            stroke="white" strokeWidth="2" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                    <div className="div-8">
                                        <div className="div-9">Scan QR-code</div>
                                        <div className="div-10">
                                            Scan the QR code with your phone to start sharing your device screen on
                                            your phone
                                        </div>
                                    </div>

                                    <QRCode className="qr-code" value={window.location.href}/>

                                    <div className="div-11">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="2" viewBox="0 0 30 2"
                                             fill="none" className="vector">
                                            <path d="M0 1H30" stroke="#E3E6E9"/>
                                        </svg>
                                        <div className="div-12">
                                            Or enter this code manually in GHEG Mobile App
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="2" viewBox="0 0 30 2"
                                             fill="none" className="vector">
                                            <path d="M0 1H30" stroke="#E3E6E9"/>
                                        </svg>
                                    </div>
                                    <div className="div-13">
                                        <div className="div-14">{code.current}</div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" className="copy-icon" onClick={copyCode}>
                                            <path
                                                d="M20 13.1251L20 6.00003C20 4.34317 18.6568 3.00002 17 3.00003L9.875 3.00012M14 21.0001L7.25 21.0001C6.00736 21.0001 5 19.9928 5 18.7501L5 9.00012C5 7.75747 6.00736 6.75011 7.25 6.75011L14 6.75011C15.2426 6.75011 16.25 7.75747 16.25 9.00011L16.25 18.7501C16.25 19.9928 15.2426 21.0001 14 21.0001Z"
                                                stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="column-2">
                                <div className="div-15">
                                    <div className="div-16">Guidelines</div>
                                    <div className="div-17">
                                        When sharing your devices, please follow these guidelines:
                                    </div>
                                    <div className="div-18">
                                        <div className="div-19">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" className="wifi-icon">
                                                <path
                                                    d="M6.01129 12.1871C9.31912 8.97669 14.6822 8.97669 17.99 12.1871M9.00596 15.0935C10.6599 13.4883 13.3414 13.4883 14.9953 15.0935M12.0006 18L12.0182 17.983M3 9.61811C7.97056 4.79396 16.0294 4.79396 21 9.61811"
                                                    stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                            </svg>
                                        </div>
                                        <div className="div-20">
                                            For successful connection ensure your phone and computer are on
                                            the same network
                                        </div>
                                    </div>
                                    <div className="div-21">
                                        <div className="div-22">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" className="mute-icon">
                                                <path
                                                    d="M21 21L17.625 18L21 15.0012L17.625 12L21 9.00004L17.625 6.00006L21 3M12.0161 5.92882L7.64072 9.50821H3V14.6616L7.64072 14.6603L12.0161 18.2409V5.92882Z"
                                                    stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                            </svg>
                                        </div>
                                        <div className="div-23">
                                            If you experience and echo, mute your phone of computer or lower your
                                            computer's speaker volume
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Session;
