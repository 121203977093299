import React, {useState} from "react";
import {Link} from "react-router-dom";
import {socket} from '../socket';

if (process.env.REACT_APP_ENV === "production") {
    console.log = () => {
    };
}

const SignIn = (props) => {
    const [btnDisabled, setBtnDisabled] = useState(false);
    const sessionId = Math.floor(100000 + Math.random() * 900000).toString();

    if (socket.connected) {
        console.log(`Socket CONNECTED (${socket.id})`)
    } else {
        console.log("Socket NOT CONNECTED (await connection, create session and redirect to room)")
        socket.on("connect", redirectToSessionRoom);
    }

    function handleCreate(event) {
        event.preventDefault();
        setBtnDisabled(true);

        redirectToSessionRoom();
    }

    function redirectToSessionRoom() {
        props.history.push({
            pathname: `/session/${sessionId}`,
            create_session: true,
            create: true,
            name: socket.id
        });
    }

    return (
        <div className="full-height">
            <div className="div">
                <Link className="div-2" to="/">
                    <img height="32" src="/logo_vce_glasses.svg" alt="logo"/>
                </Link>
                <div className="div-no-toast"></div>
                <div className="div-5">
                    <div className="div-6">
                        <div className="column">
                            <div className="div-7">
                                <svg className="icon-big-start" xmlns="http://www.w3.org/2000/svg" width="25"
                                     height="25" viewBox="0 0 38 38" fill="none">
                                    <path
                                        d="M4.75 30.0834H4.769M11.0833 30.0834C11.0833 28.4037 10.4161 26.7928 9.22834 25.6051C8.04061 24.4173 6.4297 23.7501 4.75 23.7501M17.4167 30.0834C17.4167 26.724 16.0821 23.5022 13.7067 21.1267C11.3312 18.7513 8.10941 17.4168 4.75 17.4168M23.75 30.0834H28.5C29.7598 30.0834 30.968 29.583 31.8588 28.6922C32.7496 27.8014 33.25 26.5932 33.25 25.3334V12.6667C33.25 11.407 32.7496 10.1988 31.8588 9.30799C30.968 8.41719 29.7598 7.91675 28.5 7.91675H9.5C8.52542 7.926 7.57728 8.23481 6.7842 8.8013C5.99112 9.36779 5.3915 10.1645 5.06667 11.0834"
                                        stroke="white" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                                <div className="div-8">
                                    <div className="div-9">
                                        Create a new session
                                    </div>
                                    <div className="div-10">
                                        <label>
                                            <input type="submit" disabled={btnDisabled} value="Start Session"
                                                   className={`click ${btnDisabled ? "disabled" : ""}`}
                                                   onClick={handleCreate}/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column-2">
                            <div className="div-15">
                                <div className="div-16">Guidelines</div>
                                <div className="div-17">
                                    When sharing your devices, please follow these guidelines:
                                </div>
                                <div className="div-18">
                                    <div className="div-19">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" className="wifi-icon">
                                            <path
                                                d="M6.01129 12.1871C9.31912 8.97669 14.6822 8.97669 17.99 12.1871M9.00596 15.0935C10.6599 13.4883 13.3414 13.4883 14.9953 15.0935M12.0006 18L12.0182 17.983M3 9.61811C7.97056 4.79396 16.0294 4.79396 21 9.61811"
                                                stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    <div className="div-20">
                                        For successful connection ensure your phone and computer are on
                                        the same network
                                    </div>
                                </div>
                                <div className="div-21">
                                    <div className="div-22">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" className="mute-icon">
                                            <path
                                                d="M21 21L17.625 18L21 15.0012L17.625 12L21 9.00004L17.625 6.00006L21 3M12.0161 5.92882L7.64072 9.50821H3V14.6616L7.64072 14.6603L12.0161 18.2409V5.92882Z"
                                                stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    <div className="div-23">
                                        If you experience and echo, mute your phone of computer or lower your
                                        computer's speaker volume
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignIn;